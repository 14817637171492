import React from 'react';
import { UnderConstruction } from '../components/Maintenance';

const menuLinks = {
  "Home": "/",
  "Tech Blog": "/blogs",
  "Food Blog": "/recipes"
}

const IndexPage = () => <UnderConstruction menuLinks={menuLinks} />;

export default IndexPage;
